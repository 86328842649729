<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    info-only
    overflowing
    ticker="%"
    :title="isCreate ? 'Create Alert' : 'Confirm Changes'"
    to-end-buttons
    width="70"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <template #content>
      <div class="alert-wrap mb-3">
        <o-tabs
          v-model="activeTab"
        >
          <o-tab-item
            label="Single Address"
            value="single"
          >
            <SingleAddressAlert
              ref="singleAddressAlert"
              is-create
              @close="$emit('close')"
              @success="$emit('success')"
            />
          </o-tab-item>
          <o-tab-item
            label="Bulk Upload"
            value="bulk"
          >
            <BulkAddressesAlert
              @close="$emit('close')"
              @success="$emit('success')"
            />
          </o-tab-item>
          <o-tab-item
            label="Monitoring"
            value="monitoring"
          >
            <monitoringAddressesAlert
              @close="$emit('close')"
              @success="$emit('success')"
            />
          </o-tab-item>
        </o-tabs>
      </div>
    </template>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal';
import SingleAddressAlert from "@/pages/alerting/components/singleAddressAlert.vue";
import BulkAddressesAlert from "@/pages/alerting/components/bulkAddressesAlert.vue";
import MonitoringAddressesAlert from "@/pages/alerting/components/monitoringAddressesAlert.vue";

export default {
  components: {
    MonitoringAddressesAlert,
    BulkAddressesAlert,
    SingleAddressAlert,
    GlModal,
  },
  inheritAttrs: false,
  props: {
    isCreate: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      activeTab: 'single',
    }
  },
  mounted() {

  },
  methods: {
    onSubmit() {
      if (this.isCreate) {
        this.$emit('add')
      } else {
        this.$emit('update')
      }
    },
  },
}
</script>

<style>
.new-notifications-alert {
  display: flex;
  flex-direction: column;
}
.new-notifications-alert__header {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
}
.new-notifications-alert__content {
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  cursor: pointer;
}
.new-notifications-alert__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--dark-grey-6-e);
}
.new-notifications-alert__switcher {
  display: flex;
  align-items: center;
}
.new-notifications-alert__switcher .o-switch__label {
  display: none;
}
</style>